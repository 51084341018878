export const projectObj = {
	id: 'details',
	LightBg: false,
	lighttext: true,
	lightTextDesc: true,
	topLine: 'Teamwork = making the dream work',
	headline: 'Collaboration',
	shortDescription: 'Fostering synergy, enabling diverse expertise to converge towards shared goals',
	description: `Working at Vintage Wine Estates alongside a small team of developers to manage and update over 30 websites, such as Cherry Pie Wines (linked below), while also collaborating closely with marketing leadership, was a dynamic and multifaceted endeavor. Together, we navigated the complexities of website maintenance, seamlessly integrating design updates, implementing new features, and optimizing performance across a diverse portfolio of digital platforms. My collaborative approach fostered a synergy between development and marketing objectives, ensuring that each website not only met technical standards but also aligned with strategic marketing goals and user experience objectives. In addition to these skills and responsibilities, I brought humor and positivity to the workplace. I believe in making strong connections with my coworkers to produce great results for my organization.`,
	link: 'https://www.cherrypiewines.com/',
	buttonLabel: 'Back',
	path: '/details',
	imgStart: false,
	img: require('../../images/colab.png').default,
	alt: 'Cherry Pie wines website',
	dark: true,
	primary: true,
	darkText: false,
};

export const projectObj2 = {
	id: 'details2',
	LightBg: false,
	lighttext: true,
	lightTextDesc: true,
	topLine: 'Keeping the user engaged and satisfied',
	headline: 'UI and UX',
	shortDescription: 'Striving for effortless user flow and satisfaction',
	description: `At Vintage Wine Estates, my aim was to craft a stellar user experience with solid web performance. I optimized loading times, minimized latency. For example, I once had a slow connection on one of our sites, Windsor Vineyards and noticed that I wasn't getting any visual feedback that the site was loading. I took the initiative to create a loading animation. Upon testing, we found that customers were staying on site %2.5 longer which translated into increase in revenue %.7 for that month.`,
	buttonLabel: 'Back',
	link: 'https://www.windsorvineyards.com/',
	path: '/details2',
	imgStart: false,
	img: require('../../images/UI-UX.png').default,
	alt: 'windsor vineyards website',
	dark: true,
	primary: true,
	darkText: false,
};

export const projectObj3 = {
	id: 'details3',
	LightBg: false,
	lighttext: true,
	lightTextDesc: true,
	topLine: 'A Swiss Army knife in the toolkit of life',
	headline: 'Adaptability',
	shortDescription: 'The art of thriving in the face of change, turning challenges into stepping stones for progress.',
	description: `Rapidly mastering Coresense, a web-based e-commerce platform, and Smarty templates, a PHP-based templating system, while drawing from an established code base my work facilitated the creation of an efficient workflow characterized by adaptability and agility. By swiftly familiarizing myself with these technologies, I seamlessly integrated new features, such as adding a subscription service to a site like Ace Cider (linked below), and functionalities into existing frameworks, ensuring a smooth transition and consistent development pace. This high level of adaptability has enabled me to respond swiftly to evolving project requirements, iterate efficiently, and deliver optimal solutions that meet both business objectives and user needs.`,
	buttonLabel: 'Back',
	link: 'https://shop.acecider.com/',
	path: '/details3',
	imgStart: false,
	img: require('../../images/adaptability.png').default,
	alt: 'ace cider website',
	dark: true,
	primary: true,
	darkText: false,
};

export const projectObj4 = {
	id: 'details4',
	LightBg: false,
	lighttext: true,
	lightTextDesc: true,
	topLine: 'Effortless site navigation for all!',
	headline: "Accessibilty",
	shortDescription: 'Promoting inclusivity and equal opportunities in the digital realm.',
	description: `My goal to enhance web accessibility across all Vintage Wine Estates (linked below) websites underscores a commitment to inclusivity and user-centric design. Taking full advantage of tools like Lighthouse and Axe, I meticulously evaluated each website's components, ensuring compliance with accessibility standards. By scrutinizing factors such as navigation, color contrast, and interactive elements, I was able to identify and address potential barriers for users with disabilities. Implementing measures like descriptive alt text for images, keyboard navigation enhancements, and semantic HTML markup ensured a more seamless experience for all visitors. My dedication to improving web accessibility not only aligns with ethical principles, but also strengthened Vintage Wine Estates' brand reputation, demonstrating a genuine commitment to serving diverse audiences.
`,
	buttonLabel: 'Back',
	link: 'https://www.vintagewineestates.com/',
	path: '/details4',
	imgStart: false,
	img: require('../../images/cream-accessibility.png').default,
	alt: 'web accessibility icon',
	dark: true,
	primary: true,
	darkText: false,
};